import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import {
    formatAlarm,
    formatAltitude,
    formatBoolean,
    formatBooleanSwitches,
    formatCoordinate,
    formatCourse,
    formatDistance,
    formatNumber,
    formatNumericHours, formatOutputPower,
    formatPercentage,
    formatSpeed, formatTemperature, formatTiltDegrees,
    formatTime, formatVibrationAxis,
} from '../util/formatter';
import {useAttributePreference, usePreference} from '../util/preferences';
import {useTranslation} from './LocalizationProvider';
import {useAdministrator} from '../util/permissions';
import AddressValue from './AddressValue';

const PositionValue = ({position, property, attribute}) => {
    const t = useTranslation();

    const admin = useAdministrator();

    const device = useSelector((state) => state.devices.items[position.deviceId]);

    const key = property || attribute;
    const value = property ? position[property] : position.attributes[attribute];

    const distanceUnit = useAttributePreference('distanceUnit');
    const altitudeUnit = useAttributePreference('altitudeUnit');
    const speedUnit = useAttributePreference('speedUnit');
    const coordinateFormat = usePreference('coordinateFormat');
    const hours12 = usePreference('twelveHourFormat');

    const formatValue = () => {
        switch (key) {
            case 'fixTime':
            case 'deviceTime':
            case 'serverTime':
            case 'io731':
            case 'io721':
            case 'io711':
            case 'io1035':
                return formatTime(value, 'seconds', hours12);
            case 'latitude':
            case 'io1113':
            case 'io1021':
                if (value) {
                    return formatCoordinate('latitude', value, coordinateFormat);
                }
                return "–";
            case 'longitude':
            case 'io1114':
            case 'io1022':
                if (value) {
                    return formatCoordinate('longitude', value, coordinateFormat);
                }
                return "–";
            case 'speed':
                return formatSpeed(value, speedUnit, t);
            case 'course':
                return formatCourse(value);
            case 'altitude':
            case 'io1115':
            case 'io1023':
                if (value) {
                    return formatAltitude(value, altitudeUnit, t);
                }
                return "–";
            //  Temperature IO
            case 'io206':   // ASPS:WG:temp
            case 'io406':   // ASPS:OE:temp
            case 'io506':   // ASPS:BE:temp
            case 'io606':   // ASPS:B:temp
            case 'io717':   // SSCB:E:temperature
            case 'io727':   // SSCB:SH:temperature
            case 'io737':   // SSCB:P:temperature
            case 'io1009':  // OECB:AM:airTemperature
            case 'io1102':  // OECB:W:temperature
            case 'io1030':  // OECB:W:waterTemperature
            case 'io1032':  // OECB:W:dewpoint
                return `${formatNumber(value)} °C`;
            //  Depth IO
            case 'io1101':  // OECB:W:depth
                return `${formatNumber(value)} m`;
            //  mb barometric pressure IO
            case 'io1112':  // OECB:W:depth
                return `${formatNumber(value)} mb`;
            //  Ah Discharge IO
            case 'io209':  // ASPS:WG:deepDischarge
            case 'io409':  // ASPS:OE:deepDischarge
            case 'io509':  // ASPS:BE:deepDischarge
            case 'io609':  // ASPS:BE:deepDischarge
                return `${formatNumber(value)} Ah`;
            //  Relative humidity IO
            case 'io1011':  //OECB:AM:relativeHumidity
                return `${formatNumber(value)} %RH`;
            //  Conductivity IO
            case 'io1103':  //OECB:W:conductivity
                return `${formatNumber(value)} S/m`;
            //  Salinity IO
            case 'io1104':  //OECB:W:salinity
                return `${formatNumber(value)} ppt`;
            //  pH IO
            case 'io1105':  //OECB:W:pH
                return `${formatNumber(value)} pH`;
            //  pHmV IO
            case 'io1106':  //OECB:W:pHmV
                return `${formatNumber(value)} pHmV`;
            //  orp mV IO
            case 'io1107':  //OECB:W:orp
                return `${formatNumber(value)} mV`;
            case 'io1108':  //OECB:W:dosat
                return `${formatNumber(value)} %Sat`;
            // mg/L domgl tds IO
            case 'io1109':  //OECB:W:domgl
            case 'io1110':  //OECB:W:tds
                return `${formatNumber(value)} mg/L`;
            // ɵt ssg IO
            case 'io1111':  //OECB:W:ssg
                return `${formatNumber(value)} ɵt`;
            // kn windSpeed IO
            case 'io1003':  //OECB:AM:windSpeedKnots
                return `${formatNumber(value)} kn`;
            // inches Hg barometricPressure IO
            case 'io1015':  //OECB:AM:barometricPressureInInches
                return `${formatNumber(value)} inches Hg`;
            //  barometricPressure IO
            case 'io1013':   // OECB:AM:barometricPressure
                if (typeof value === 'number') {
                    return `${formatNumber(value * 1000)} hPa`;
                }
                return "N/A hPa";
            //  Tilt IO
            case 'io725':  // SSCB:SH:tiltX
            case 'io726':  // SSCB:SH:tiltX
            case 'io1005': // OECB:AM:windDirectionTrue
            case 'io1007': // OECB:AM:windDirectionMagnetic
            case 'io1017': // OECB:AM:pitch
            case 'io1019': // OECB:AM:roll
            case 'io1034': // OECB:AM:windHeading
                return `${formatNumber(value)} °`;
            //  Vibrations IO
            case 'io712':  // SSCB:E:vibrationsX
            case 'io713':  // SSCB:E:vibrationsY
            case 'io714':  // SSCB:E:vibrationsZ
            case 'io722':  // SSCB:SH:vibrationsX
            case 'io723':  // SSCB:SH:vibrationsY
            case 'io724':  // SSCB:SH:vibrationsZ
            case 'io732':  // SSCB:P:vibrationsX
            case 'io733':  // SSCB:P:vibrationsY
            case 'io734':  // SSCB:P:vibrationsZ
                return `${formatNumber(value)} m/s2`;
            //  Voltage IO
            case 'io101':  // ASPS:SP:voltage
            case 'io104':  // ASPS:SP:pvV
            case 'io107':  // ASPS:SP:maxBatVoltage
            case 'io108':  // ASPS:SP:maxPvV
            case 'io109':  // ASPS:SP:minBatVoltage
            case 'io201':  // ASPS:WG:voltage
            case 'io205':  // ASPS:WG:voltage1
            case 'io211':  // ASPS:WG:maxV
            case 'io212':  // ASPS:WG:minStarterVoltage
            case 'io213':  // ASPS:WG:minV
            case 'io304':  // ASPS:MSPS:voltage
            case 'io401':  // ASPS:OE:voltage
            case 'io405':  // ASPS:OE:voltage1
            case 'io411':  // ASPS:OE:maxV
            case 'io412':  // ASPS:OE:minStarterVoltage
            case 'io413':  // ASPS:OE:minV
            case 'io501':  // ASPS:BE:voltage
            case 'io505':  // ASPS:BE:voltage1
            case 'io511':  // ASPS:BE:maxV
            case 'io512':  // ASPS:BE:minStarterVoltage
            case 'io513':  // ASPS:BE:minV
            case 'io601':  // ASPS:B:voltage
            case 'io605':  // ASPS:B:voltage1
            case 'io611':  // ASPS:B:maxV
            case 'io612':  // ASPS:B:minStarterVoltage
            case 'io613':  // ASPS:B:minV
                return `${formatNumber(value)} V`;
            //  kWh ySystem and user IO
            case 'io105':  // ASPS:SP:ySystem
            case 'io106':  // ASPS:SP:user
            case 'io208':  // ASPS:SP:chEnergy
            case 'io210':  // ASPS:SP:disEnergy
            case 'io408':  // ASPS:OE:chEnergy
            case 'io410':  // ASPS:OE:disEnergy
            case 'io508':  // ASPS:BE:chEnergy
            case 'io510':  // ASPS:BE:disEnergy
            case 'io608':  // ASPS:B:chEnergy
            case 'io610':  // ASPS:B:disEnergy
                return `${formatNumber(value)} kWh`;
            //  I Current IO
            case 'io103':  // ASPS:SP:current
            case 'io203':  // ASPS:WG:current
            case 'io303':  // ASPS:WG:current
            case 'io403':  // ASPS:OE:current
            case 'io503':  // ASPS:BE:current
            case 'io603':  // ASPS:B:current
                return `${formatNumber(value)} I`;
            case 'batteryLevel':
            case 'io306':   // ASPS:MSPS:soc
            case 'io615':   // ASPS:B:soc
                return formatPercentage(value, t);
            //  outputPower IO
            case 'io102':   // ASPS:SP:outputPower
            case 'io204':   // ASPS:WG:outputPower
            case 'io305':   // ASPS:WG:outputPower
            case 'io404':   // ASPS:OE:outputPower
            case 'io504':   // ASPS:BE:outputPower
            case 'io604':   // ASPS:B:outputPower
                return `${formatNumber(value)} W`;
            //  Switches IO
            case 'io1402':   // ASPS:S:S2
            case 'io1403':   // ASPS:S:S3
            case 'io1404':   // ASPS:S:S4
            case 'io1405':   // ASPS:S:S5
            case 'io1406':   // ASPS:S:S6
            case 'io1407':   // ASPS:S:S7
                if (typeof value === 'boolean') {
                    return formatBooleanSwitches(value);
                }
                return value || "N/A";
            //  windSpeed IO
            case 'io1001':   // OECB:AM:windSpeedMS
                return `${formatNumber(value)} m/s`;
            //  km/h speed AM IO
            case 'io1028':   // OECB:AM:speed
                return `${formatNumber(value)} m/s`;
            case 'alarm':
                return formatAlarm(value, t);
            case 'odometer':
            case 'distance':
            case 'totalDistance':
                return formatDistance(value, distanceUnit, t);
            case 'hours':
                return formatNumericHours(value, t);
            default:
                if (typeof value === 'number') {
                    return formatNumber(value);
                }
                if (typeof value === 'boolean') {
                    return formatBoolean(value, t);
                }
                return value || 'N/A';
        }
    };

    switch (key) {
        case 'image':
        case 'video':
        case 'audio':
            return (<Link href={`/api/media/${device.uniqueId}/${value}`} target="_blank">{value}</Link>);
        case 'totalDistance':
        case 'hours':
            return (
                <>
                    {formatValue(value)}
                    &nbsp;&nbsp;
                    {admin && (<Link component={RouterLink} underline="none"
                                     to={`/settings/accumulators/${position.deviceId}`}>&#9881;</Link>)}
                </>
            );
        case 'address':
            return (
                <AddressValue latitude={position.latitude} longitude={position.longitude} originalAddress={value}/>);
        case 'network':
            if (value) {
                return (<Link component={RouterLink} underline="none"
                              to={`/network/${position.id}`}>{t('sharedInfoTitle')}</Link>);
            }
            return '';
        default:
            return formatValue(value);
    }
};

export default PositionValue;
