import {
    Button,
    Card, CardActions,
    CardContent, Divider,
    Grid,
    Typography
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import PositionValue from "../../common/components/PositionValue";


const MonitoringMainDashboardSscbCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    const navigate = useNavigate();

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.outerCardContentTitleText}>
                    <Typography variant="h5" component="div">
                        SSCB
                    </Typography>
                </div>
                <div style={{padding: "8px"}}>
                    <Card variant="outlined" className={classes.cardMarginBottom}>
                        <CardContent style={{padding: 0}}>
                            <div className={classes.cardContentTitleText}>
                                <Typography variant="h5" component="div">
                                    Ship hull
                                </Typography>
                            </div>
                            <div className={classes.cardContentText}>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration X
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io722"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration Y
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io723"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration Z
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io724"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            tilt X
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io725"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            tilt Y
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io726"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            temperature
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io727"}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" className={classes.cardMarginBottom}>
                        <CardContent style={{padding: 0}}>
                            <div className={classes.cardContentTitleText}>
                                <Typography variant="h5" component="div">
                                    Engine
                                </Typography>
                            </div>
                            <div className={classes.cardContentText}>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration X
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io712"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration Y
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io713"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration Z
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io714"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            temperature
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io717"}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" className={classes.cardMarginBottom}>
                        <CardContent style={{padding: 0}}>
                            <div className={classes.cardContentTitleText}>
                                <Typography variant="h5" component="div">
                                    Pump
                                </Typography>
                            </div>
                            <div className={classes.cardContentText}>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration X
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io732"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration Y
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io733"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            vibration Z
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io734"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            temperature
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io737"}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button size="small" onClick={() => navigate('/monitoring/sscbdashboard')}>
                    Detailed view
                </Button>
            </CardActions>
        </Card>
    )
};

export default MonitoringMainDashboardSscbCardComponent;
