import React, {useEffect, useState} from "react";
import PageLayout from "../common/components/PageLayout";
import MonitoringMenu from "./components/MonitoringMenu";
import {
    Grid,
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import MonitoringOecbDashboardAirMonitoringCardComponent
    from "./components/oecb/MonitoringOecbDashboardAirMonitoringCardComponent";
import MonitoringOecbDashboardWaterMonitoringCardComponent
    from "./components/oecb/MonitoringOecbDashboardWaterMonitoringCardComponent";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    sidebar: {
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            position: 'fixed',
            left: 0,
            top: 0,
            height: `calc(100% - ${theme.spacing(3)})`,
            width: theme.dimensions.drawerWidthDesktop,
            margin: theme.spacing(1.5),
            zIndex: 3,
        },
        [theme.breakpoints.down('md')]: {
            height: '100%',
            width: '100%',
        },
    },
    header: {
        pointerEvents: 'auto',
        zIndex: 6,
    },
    footer: {
        pointerEvents: 'auto',
        zIndex: 5,
    },
    middle: {
        flex: 1,
        display: 'grid',
    },
    contentMap: {
        pointerEvents: 'auto',
        gridArea: '1 / 1',
    },
    contentList: {
        display: "flex",
        height: '100%',
        top: "0px",
        pointerEvents: 'auto',
        gridArea: '1 / 1',
        zIndex: 4,
    },
    filterPanel: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        width: theme.dimensions.drawerWidthTablet,
    },
    cardMarginBottom: {
        marginBottom: "8px",
    },
    cardContent: {
        padding: "0px",
    },
    outerCardContentTitleText: {
        backgroundColor: "#80dabc",
        // border: "1px solid green",
        borderRadius: "2px",
        padding: "8px",
    },
    cardContentTitleText: {
        backgroundColor: "#c9eedf",
        // border: "1px solid green",
        borderRadius: "2px",
        padding: "8px",
    },
    cardContentText: {
        padding: "0 8px 0px 8px",
    },
    cardActions: {
        padding: "4px",
        justifyContent: "right",
    },
}));

const MonitoringOecbDashboardPage = () => {

    const classes = useStyles();
    const [lastPosition, setLastPosition] = useState({lastPosition: {}});
    const positions = useSelector((state) => state.session.positions);

    useEffect(() => {
        setLastPosition(Object.values(positions))
    }, [positions]);

    return (
        <PageLayout menu={<MonitoringMenu/>} breadcrumbs={['monitoringTitle', 'sharedCustomOecbDashboard']}>
                    <Grid
                        container
                        spacing={1}
                        overflow="scroll"
                        justifyContent="start"
                        padding="15px"
                        alignItems="stretch"
                    >
                        <Grid item xs={12} lg={6}>
                        {lastPosition.length > 0 && <MonitoringOecbDashboardAirMonitoringCardComponent styleclass={classes} lastPosition={lastPosition[0]} />}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            {lastPosition.length > 0 && <MonitoringOecbDashboardWaterMonitoringCardComponent styleclass={classes} lastPosition={lastPosition[0]} />}
                        </Grid>
                    </Grid>
        </PageLayout>
    )
};

export default MonitoringOecbDashboardPage;
