import {Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import PositionValue from "../../../common/components/PositionValue";

const MonitoringOecbDashboardWaterMonitoringCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.cardContentTitleText}>
                    <Typography variant="h5" component="div">
                        Water Monitoring
                    </Typography>
                </div>
                <div>
                    <Grid container spacing={1} p={1}>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                depth
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1101"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                temperature
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1102"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                conductivity
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1103"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                salinity
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1104"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                pH
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1105"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                pHmV
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1106"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                orp
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1107"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                dosat
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1108"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                domgl
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1109"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                tds
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1110"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                ssg
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1111"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                barometricPressure
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1112"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                lat
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1113"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                lng
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1114"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                alt
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1115"}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
};

export default MonitoringOecbDashboardWaterMonitoringCardComponent;
