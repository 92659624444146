import {Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import PositionValue from "../../../common/components/PositionValue";


const MonitoringAspsDashboardWindGeneratorCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.cardContentTitleText}>
                    <Typography variant="h5" component="div">
                        Wind Generator SH1
                    </Typography>
                </div>
                <div>
                    <Grid container spacing={1} p={1}>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                voltage
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io201"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                outputPower
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io204"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                current
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io203"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                temperature
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io206"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                voltage1
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io205"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                chEnergy
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io208"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                deepDischarge
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io209"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                disEnergy
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io210"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                maxV
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io211"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                minStarterVoltage
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io212"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                minV
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io213"}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
};

export default MonitoringAspsDashboardWindGeneratorCardComponent;
