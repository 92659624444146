import React from 'react';
import {useMediaQuery, Paper, Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useTheme} from '@mui/material/styles';
import InnoImage from '../resources/images/inno.png';
import GrantsImage from '../resources/images/grants.png';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    sidebar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.primary.main,
        paddingBottom: theme.spacing(5),
        width: theme.dimensions.sidebarWidth,
        [theme.breakpoints.down('lg')]: {
            width: theme.dimensions.sidebarWidthTablet,
        },
        [theme.breakpoints.down('sm')]: {
            width: '0px',
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    form: {
        maxWidth: theme.spacing(52),
        padding: theme.spacing(5),
        width: '100%',
    },
    jss14: {
        display: "grid",
        gridTemplateColumns: "auto auto auto auto",
    },
    jss13: {
        padding: "10px",
        fontSize: "0.8rem",
        textAlign: "center",
        lineHeight: "0.4em",
    },
    jss15: {
        display: "grid",
        width: "100%",
        gridTemplateColumns: "auto",
    }
}));

const LoginLayout = ({children}) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <main className={classes.root}>
            {/*<div className={classes.sidebar}>*/}
            {/*  /!*Traccar logo removed*!/*/}
            {/*  /!*{!useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.secondary.contrastText} />}*!/*/}
            {/*</div>*/}
            <Paper className={classes.paper}>
                <form className={classes.form}>
                    {children}
                </form>

                <div className={classes.jss14}>
                    {/*<div className={classes.jss13}>*/}
                        <Box
                            component="img"
                            sx={{
                                maxHeight: {xs: 100},
                                paddingRight: "2rem",
                                margin: "1rem 0 1rem 0",
                            }}
                            alt="grants logo"
                            src={GrantsImage}
                        />
                        <Box
                             component="img"
                             sx={{
                                 maxHeight: {xs: 100},
                                 maxWidth: {xs: 200},
                                 margin: "1rem 0 1rem 0",
                             }}
                             alt="innonorway logo"
                             src={InnoImage}
                        />
                    {/*</div>*/}
                </div>
                <div className={classes.jss15}>
                    <div className={classes.jss13}><p><b>Project: Integrated innovative VMS for boats and small ships -
                        I2VMS</b></p><p>
                        <b>Supported by the Norway Grants 2014-2022, in the frame of the Programme Business Development,
                            Innovation
                            and SMEs, Bulgaria</b></p>
                        <div className={classes.jss14}>
                            <div className={classes.jss13}><b>Innovation Norway</b></div>
                            <div className={classes.jss13}><a
                                href="https://www.innovasjonnorge.no">https://www.innovasjonnorge.no</a></div>
                            <div className={classes.jss13}></div>
                            <div className={classes.jss13}></div>
                            <div className={classes.jss13}>Project budget: 327 120 EUR</div>
                            <div className={classes.jss13}>Grant: 196 000 EUR</div>
                            <div className={classes.jss13}>Duration: 24 months, within April 30, 2023</div>
                            <div className={classes.jss13}>Project Contract: 2020/551621</div>
                            <div className={classes.jss13}>Project Promoter: Scortel Ltd</div>
                            <div className={classes.jss13}><a href="https://scortel.com">https://www.scortel.com</a>
                            </div>
                            <div className={classes.jss13}>Project Partner: FourC AS</div>
                            <div className={classes.jss13}><a href="https://fourc.eu">https://www.fourc.eu</a></div>
                        </div>
                    </div>
                    {/*<hr style="width: 100vw; border: 1px solid red;" />*/}
                    <hr style={{width: "100%", border: "1px solid red"}}/>
                    <div className={classes.jss13}><p><b>Проект: Интегрирана иновативна СНК за моторни лодки и малки
                        кораби I2VMS</b>
                    </p><p><b>Сфинансовата подкрепа на Норвежкия финансов механизъм 2014-2021, в рамките на програма
                        "Развитие
                        на бизнеса, иновациите и МСП в България"</b></p>
                        <div className={classes.jss14}>
                            <div className={classes.jss13}><b>Иновация Норвегия</b></div>
                            <div className={classes.jss13}><a
                                href="https://www.innovasjonnorge.no">https://www.innovasjonnorge.no</a></div>
                            <div className={classes.jss13}></div>
                            <div className={classes.jss13}></div>
                            <div className={classes.jss13}>Бюджет на проекта: 327 120 EUR</div>
                            <div className={classes.jss13}>Грант: 196 000 EUR</div>
                            <div className={classes.jss13}>Продължителност: 24 месеца, до 30.04.2023 г.</div>
                            <div className={classes.jss13}>Проектен договор: 2020/551621</div>
                            <div className={classes.jss13}>Водеща фирма (Бенефициент): СКОРТЕЛ ООД</div>
                            <div className={classes.jss13}><a href="https://scortel.com">https://www.scortel.com</a>
                            </div>
                            <div className={classes.jss13}>Партньор по проекта: FourC AS</div>
                            <div className={classes.jss13}><a href="https://fourc.eu">https://www.fourc.eu</a></div>
                        </div>
                    </div>
                </div>
            </Paper>
        </main>
    );
};

export default LoginLayout;
