import {Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import PositionValue from "../../../common/components/PositionValue";


const MonitoringAspsDashboardSolarPanelCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.cardContentTitleText}>
                    <Typography variant="h5" component="div">
                        Solar Panel
                    </Typography>
                </div>
                <div>
                    <Grid container spacing={1} p={1}>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                voltage
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io101"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                outputPower
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io102"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                current
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io103"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                pvV
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io104"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                ySystem
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io105"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                user
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io106"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                maxBatVoltage
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io107"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                maxPvV
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io108"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                minBatVoltage
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io109"}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
};

export default MonitoringAspsDashboardSolarPanelCardComponent;
