import {Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import PositionValue from "../../../common/components/PositionValue";

const MonitoringOecbDashboardAirMonitoringCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.cardContentTitleText}>
                    <Typography variant="h5" component="div">
                        Air Monitoring
                    </Typography>
                </div>
                <div>
                    <Grid container spacing={1} p={1}>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                latitude
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1021"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                longitude
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1022"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                altitude
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1023"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                gpsQuality
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1024"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                satinview
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1025"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                hdop
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1026"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                windSpeed
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1001"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                windSpeedKn
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1003"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                windDirection
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1005"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                windDirection Magnetic
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1007"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                windHeading
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1034"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                airTemperature
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1009"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                relativeHumidity
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1011"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                barometricPressure
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1013"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                barometricPressure Inches
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1015"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                dewpoint
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1032"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                waterTemperature
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1030"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                course
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1027"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                speed
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1028"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                pitch
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1017"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                roll
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1019"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                timestamp
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io1035"}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
};

export default MonitoringOecbDashboardAirMonitoringCardComponent;
