import {Card, CardContent, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import PositionValue from "../../../common/components/PositionValue";


const MonitoringAspsDashboardMspsCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.cardContentTitleText}>
                    <Typography variant="h5" component="div">
                        System Record
                    </Typography>
                </div>
                <div>
                    <Grid container spacing={1} p={1}>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                current
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io303"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                voltage
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io304"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                power
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io305"}/>
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} xl={4}>
                            <Typography color="text.secondary" variant="subtitle1">
                                SoC
                            </Typography>
                            <Divider/>
                            <Typography variant="body1">
                                <PositionValue position={lastPosition} attribute={"io306"}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
        </Card>
    )
};

export default MonitoringAspsDashboardMspsCardComponent;
