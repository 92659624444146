import {
    Button,
    Card, CardActions,
    CardContent, Divider,
    Grid,
    Typography
} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import PositionValue from "../../common/components/PositionValue";


const MonitoringMainDashboardOecbCardComponent = (props) => {
    const classes = props.styleclass;
    const lastPosition = props.lastPosition;

    const navigate = useNavigate();

    return (
        <Card variant="outlined">
            <CardContent style={{padding: 0}}>
                <div className={classes.outerCardContentTitleText}>
                    <Typography variant="h5" component="div">
                        OECB
                    </Typography>
                </div>
                <div style={{padding: "8px"}}>
                    <Card variant="outlined" className={classes.cardMarginBottom}>
                        <CardContent style={{padding: 0}}>
                            <div className={classes.cardContentText}>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            datetime
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} property={"deviceTime"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            latitude
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} property={"latitude"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            longitude
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} property={"longitude"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            speed
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} property={"speed"}/>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            course
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} property={"course"}/>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" className={classes.cardMarginBottom}>
                        <CardContent style={{padding: 0}}>
                            <div className={classes.cardContentTitleText}>
                                <Typography variant="h5" component="div">
                                    Air
                                </Typography>
                                {/*<Typography color="text.secondary">*/}
                                {/*    On/Off/Other*/}
                                {/*</Typography>*/}
                            </div>
                            <div className={classes.cardContentText}>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            windSpeed
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1001"}/>
                                            {/*{console.log("io1001 " + [lastPosition.attributes.io1001] + " type: " + typeof lastPosition.attributes.io1001)}*/}
                                            {/*{lastPosition.attributes.hasOwnProperty("io1001") ? lastPosition.attributes.io1001.toFixed(2) : "N/A io1001"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            windDirection
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1005"}/>
                                            {/*{lastPosition.attributes.hasOwnProperty("io1005") ? lastPosition.attributes.io1005.toFixed(2) : "N/A io1005"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            temperature
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1009"}/>
                                            {/*{lastPosition.attributes.hasOwnProperty("io1009") ? lastPosition.attributes.io1009.toFixed(2) : "N/A io1009"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            relativeHumidity
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1011"}/>
                                            {/*{lastPosition.attributes.hasOwnProperty("io1011") ? lastPosition.attributes.io1011.toFixed(2) : "N/A io1011"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            barometricPressure
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1013"}/>
                                            {/*{lastPosition.attributes.hasOwnProperty("io1013") ? lastPosition.attributes.io1013.toFixed(2) : "N/A io1013"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" className={classes.cardMarginBottom}>
                        <CardContent style={{padding: 0}}>
                            <div className={classes.cardContentTitleText}>
                                <Typography variant="h5" component="div">
                                    Water
                                </Typography>
                                {/*<Typography color="text.secondary">*/}
                                {/*    On/Off/Other*/}
                                {/*</Typography>*/}
                            </div>
                            <div className={classes.cardContentText}>
                                <Grid container spacing={1} p={1}>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            depth
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1101"}/>
                                            {/*{lastPosition.attributes.hasOwnProperty("io1101") ? lastPosition.attributes.io1101 : "N/A io1101"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            temperature
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1102"}/>
                                            {/*{lastPosition.attributes.hasOwnProperty("io1102") ? lastPosition.attributes.io1102 : "N/A io1102"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            conductivity
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1103"}/>
                                            {/*{lastPosition.attributes.hasOwnProperty("io1103") ? lastPosition.attributes.io1103 : "N/A io1103"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography color="text.secondary" variant="subtitle1">
                                            salinity
                                        </Typography>
                                        <Divider/>
                                        <Typography variant="body1">
                                            <PositionValue position={lastPosition} attribute={"io1104"}/>
                                            {/*{lastPosition.attributes.hasOwnProperty("io1104") ? lastPosition.attributes.io1104 : "N/A io1104"}*/}
                                        </Typography>
                                        {/*<Typography color="text.secondary" variant="subtitle2">*/}
                                        {/*    datetime*/}
                                        {/*</Typography>*/}
                                    </Grid>
                                </Grid>
                            </div>
                        </CardContent>
                    </Card>
                    <Grid container spacing={1} p={1}>
                        <Grid item xs={6}>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Operations
                                        </Typography>
                                    </div>
                                    <div className={classes.cardContentText}>
                                        <Grid container spacing={1} p={1}>
                                            <Grid item xs={4}>
                                                <Typography variant="body1">
                                                    ...
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card variant="outlined" className={classes.cardMarginBottom}>
                                <CardContent style={{padding: 0}}>
                                    <div className={classes.cardContentTitleText}>
                                        <Typography variant="h5" component="div">
                                            Video
                                        </Typography>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button size="small" onClick={() => navigate('/monitoring/oecbdashboard')}>
                    Detailed view
                </Button>
            </CardActions>
        </Card>
    )
};

export default MonitoringMainDashboardOecbCardComponent;
